<template>
  <div>
    <!-- ***** Header Area Start ***** -->
    <header class="header-area header-sticky">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <nav class="main-nav">
              <!-- ***** Logo Start ***** -->
              <a class="logo">
                <img style="width: 30%;" src="../../../public/assets/images/logo.svg" alt="">
              </a>
              <!-- ***** Logo End ***** -->
              <!-- ***** Menu Start ***** -->
              <ul class="nav">
                <li class="scroll-to-section"><a style="color: black;" href="#" v-scroll-to="'#top'"
                    class="active">Home</a>
                </li>
                <li class="scroll-to-section"><a style="color: black;" href="#" v-scroll-to="'#about'">About</a></li>
                <li class="scroll-to-section"><a style="color: black;" href="#" v-scroll-to="'#projects'">Plans</a></li>
                <li class="scroll-to-section"><a style="color: black;" href="#" v-scroll-to="'#contact-us'">Contact Us</a>
                </li>
                <li>
                  <router-link to="/login" class="btn btn-success lineheight">{{ $t("nav.login") }}</router-link>

                </li>
              </ul>
              <a class='menu-trigger'>
                <span>Menu</span>
              </a>
              <!-- ***** Menu End ***** -->
            </nav>
          </div>
        </div>
      </div>
    </header>
    <!-- ***** Header Area End ***** -->

    <!-- ***** Main Banner Area Start ***** -->

      <div class="main-banner header-text" id="top">
        <div class="Modern-Slider">
          <!-- Item -->
          <div class="item">
            <div class="img-fill" style="background-color: #DBF255; color: black">

              <div class="text-content">
                <h1 class="heroTitle">We are Ojour</h1>
                <h5 style="color: black; font-weight: 400; margin-top: 10px;margin-bottom: 30px;">Fair Pay Based
                  on data!</h5>
                <h6 class="heroDesc">We are a platform that helps
                  businesses
                  grow by providing accurate data. Know your salary
                  range penetration in
                  the market.
                </h6>
                <div class="flex-container">
                  <a href="#" v-scroll-to="'#about'" class="main-stroked-button">Learn More</a>
                  <a href="#" v-scroll-to="'#projects'" class="main-filled-button">Our Plans</a>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div class="scroll-down scroll-to-section"><a href="#about"><i class="fa fa-arrow-down"></i></a></div>
      </div><!-- ***** Main Banner Area End ***** -->

      <!-- ***** About Area Starts ***** -->
      <section class="section" id="about">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-xs-12">
              <div class="left-text-content">
                <div class="section-heading">
                  <h6 style="color: #FF5831;">About Us</h6>
                  <h2>Our data is reliable and robust, fair pay starts now!</h2>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <div class="service-item">
                      <img src="../../../public/assets/images/hand.svg" alt="">
                      <h4>Premuim Partners</h4>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="service-item">
                      <img src="../../../public/assets/images/rocket.svg" alt="">
                      <h4>Up-to-date</h4>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="service-item">
                      <img src="../../../public/assets/images/world_alt2.svg" alt="">
                      <h4>Global Reach</h4>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6">
                    <div class="service-item">
                      <img src="../../../public/assets/images/world_alt2.svg" alt="">
                      <h4>Fair Pricing</h4>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <a href="#" class="main-button-icon">
                      Learn More <i class="fa fa-arrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-xs-12">
              <div class="right-text-content">
                <p style="margin-bottom: 15px;">
                  Ojour is a cutting-edge data analytics company specializing in providing comprehensive
                  wage comparison services for employees and job seekers. Founded in 2023, the company
                  is dedicated to empowering individuals with accurate and up-to-date salary information
                  to make informed decisions about their careers and negotiate fair compensation packages.
                </p>
                <p>
                  Ojour's mission is to bridge the wage gap by providing transparency and empowering
                  individuals with valuable salary data. The company envisions a world where everyone has
                  access to fair compensation and can confidently navigate their careers based on accurate
                  and relevant information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ***** About Area Ends ***** -->

      <div class="container" style="padding-top: 40px; padding-bottom: 100px;" id="projects">
        <div class="row justify-content-center text-center">
          <div class="col-lg-12">
            <h2 style="font-family: Inter; font-weight: 900;">Our Pricing Plan</h2>
            <p style="color: black; margin-bottom: 20px; margin-top: 10px;">Flexible plans for all
              users, we adhere to individuals, companies and more</p>
          </div>
        </div>
        <div class="row" v-if="items">
          <div class="text-center">
            <!--begin::Nav group-->
            <div class="nav-group d-inline-flex mb-15" data-kt-buttons="true" style="border: 1px dashed #2B4666;">
              <a @click="selectedPackagePeriod1 = 2" :class="{ active: selectedPackagePeriod1 === 2 }"
                class="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3 me-2"
                data-kt-plan="month">Semi-Annual</a>
              <a @click="selectedPackagePeriod1 = 3" :class="{ active: selectedPackagePeriod1 === 3 }"
                class="btn btn-color-gray-600 btn-active btn-active-success px-6 py-3" data-kt-plan="annual">Annual</a>
            </div>
          </div>
          <div class="col-lg-4" v-for="(item, index) in items" :key="item.id">
            <div style="background-color: #DBF255; border-radius: 12px; margin-bottom: 20px; margin-top: 20px;"
              class="  mt-3 price-box">
              <div class="pricing-name text-center">
                <h1 class="mb-0" style="color: black;">{{ item.packageName }}</h1>
              </div><!--begin::Description-->
              <!-- <div class="text-gray-400 fw-semibold mb-5">Guaranteed Annual Pay and
                Bonus bi-annual / annual report
              </div> -->

              <div class="plan-price text-center mt-4" v-if="selectedPackagePeriod1 == 2">
                <h1 style="color: black;" class="text-custom font-weight-normal mb-0">EGP
                  {{ item.halfYearFees
                  }}
                  <span>/ Semi-Annual</span>
                </h1>
              </div>
              <div class="plan-price text-center mt-4" v-if="selectedPackagePeriod1 == 3">
                <h1 style="color: black;" class="text-custom font-weight-normal mb-0">EGP
                  {{ item.yearFees
                  }} <span>/ Annual</span>
                </h1>
              </div>

              <div class="price-features mt-5" v-if="item.reports && reprotsList"
                style="display: flex; flex-direction: column; width: 100%; gap: 15px; align-items: center;">
                <div style="display: flex;  gap: 10px; width: fit-content;"
                  v-for="(report, indx) in getReprotsList(item.reports, index)" :key="indx">
                  <div
                    style="width: 20px; height: 20px; border-radius: 100px; background-color: white; display: flex; justify-content: center; padding:4px;">
                    <img style="width: 100px;" src="../../../public/assets/images/tick.svg" alt="">
                  </div>
                  <h6 style="max-width: 250px;">{{ indx }}: <span class="font-weight-bold">{{ report.reportName
                  }}</span>
                  </h6>
                </div>


              </div>
              <div class="text-center mt-5">
                <a class="main-filled-button" @click="subscribe(index)">{{ $t("registerationPackages.subscribe") }}</a>
              </div>
            </div>
          </div>



        </div>
      </div>



    <!-- ***** Features Big Item Start ***** -->
    <section class="section">
      <div class="container" id="subscribe">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="section-heading">
              <h6>Sign up to be notified when we launch</h6>
              <h2>Don’t miss this chance, for a speciality discount!</h2>
            </div>
            <div class="subscribe-content">
              <p>Being an early bird has it's perks, enter your email to be enrolled in our loyalty
                prograam
              </p>
              <div class="subscribe-form">
                <form id="subscribe-now" v-on:submit.prevent="sendMainForm" method="get">
                  <div class="row">
                    <div class="col-md-8 col-sm-12">
                      <fieldset>
                        <input style="height: 48px; border-radius: 100px;" name="email" type="text" id="email"
                          placeholder="Enter your email..." required="">
                      </fieldset>
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <fieldset>
                        <button type="submit" id="form-submit" class="main-button">Subscribe
                          Now</button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ***** Features Big Item End ***** -->
      <!-- ***** Contact Us Area Starts ***** -->
      <section class="section" id="contact-us">
        <Toast />
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-xs-12">
              <div class="left-text-content">
                <div class="section-heading">
                  <h6 style="color: #FF5831;">Contact Us</h6>
                  <h2 style="color: black  !important;">Feel free to keep in touch with us!</h2>
                </div>
                <ul class="contact-info">
                  <li><img style="width: 12%;" src="../../../public/assets/images/phone.svg" alt="">010-020-0860
                  </li>
                  <li><img style="width: 12%;" src="../../../public/assets/images/message.svg" alt="">info@company.com
                  </li>
                  <li><img style="width: 12%;" src="../../../public/assets/images/world_alt2.svg" alt="">www.company.com
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-8 col-md-8 col-xs-12">
              <div class="contact-form">
                <form v-on:submit.prevent="sendMainForm" id="contact" action="" method="post">
                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <fieldset>
                        <input v-model="formValues.name" name="name" type="text" id="name" placeholder="Your Name *"
                          required="">
                      </fieldset>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <fieldset>
                        <input v-model="formValues.phone" name="phone" type="text" id="phone" placeholder="Your Phone"
                          required="">
                      </fieldset>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <fieldset>
                        <input v-model="formValues.email" name="email" type="email" id="email" placeholder="Your Email *"
                          required="">
                      </fieldset>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <fieldset>
                        <input v-model="formValues.subject" name="subject" type="text" id="subject" placeholder="Subject">
                      </fieldset>
                    </div>
                    <div class="col-lg-12">
                      <fieldset>
                        <textarea v-model="formValues.message" name="message" rows="6" id="message" placeholder="Message"
                          required=""></textarea>
                      </fieldset>
                    </div>
                    <div class="col-lg-12">
                      <fieldset>
                        <button type="submit" id="form-submit" class="main-button-icon">Send Message
                          Now <i class="fa fa-arrow-right"></i></button>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ***** Contact Us Area Ends ***** -->


    <!-- ***** Footer Start ***** -->
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-xs-12">
            <div class="left-text-content">
              <p>Copyright &copy; 2023 Ojour Co., Ltd.

              </p>
            </div>
          </div>
          <div class="col-lg-6 col-xs-12">
            <div class="right-text-content">
              <ul class="social-icons">
                <li>
                  <p>Follow Us</p>
                </li>
                <li><a rel="nofollow" href=""><i style="color: #FF5831;" class="fa fa-facebook"></i></a>
                </li>
                <li><a rel="nofollow" href=""><i style="color: #FF5831;" class="fa fa-twitter"></i></a>
                </li>
                <li><a rel="nofollow" href=""><i style="color: #FF5831;" class="fa fa-linkedin"></i></a>
                </li>
                <li><a rel="nofollow" href=""><i style="color: #FF5831;" class="fa fa-dribbble"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </footer>






  </div>
</template>


<style scoped>
@import '../../../assets/plugins/global/plugins.bundle.css';
@import '../../../assets/css/style.bundle.css';

.lineheight {
  line-height: normal !important;
}

a.main-filled-button {
  color: white !important;
}

.headerbg {
  background-image: url(../../../assets/media/svg/illustrations/landing.svg)
}

.octagonbg {
  background-image: url('../../../assets/media/svg/misc/octagon.svg')
}

.octagonbg1 {
  background-image: url('../../../assets/media/avatars/300-1.jpg')
}

.octagonbg2 {
  background-image: url('../../../assets/media/avatars/300-2.jpg')
}

.octagonbg3 {
  background-image: url('../../../assets/media/avatars/300-5.jpg')
}

.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
<script>
// // import '../../assets/landingPage/js/bootstrap';
// import '../../assets/landingPage/js/kl-plugins';
import Toast from './Toast.vue';
import VueScrollTo from 'vue-scrollto';

export default {
  data() {
    return {
      formValues: { name: "", phone: "", email: "", subject: "", message: "" },
      selectedPackagePeriod1: 2,
      selectedPackagePeriod2: "3",
      selectedPackagePeriod3: "3",
      reprotsList: null,
      packages: [],
      items: [],
      colors: [
        'orange',
        'teal',
        'blue',
      ],
    }
  },
  components: { Toast: Toast },
  methods: {
    subscribe(pkgIndex) {
      let selectedPeriod = this.selectedPackagePeriod1;
      let subscriptionFees = 0;

      switch (selectedPeriod) {
        case "1":
          subscriptionFees = this.items[pkgIndex].quarterFees
          break;
        case "2":
          subscriptionFees = this.items[pkgIndex].halfYearFees
          break;
        case "3":
          subscriptionFees = this.items[pkgIndex].yearFees
          break;
      }
      // this.$router.push({ name: 'userRegisterationInfo',
      // params:{ package:this.items[pkgIndex] , packagePeriod: selectedPeriod} });
      let pkg = {
        id: this.items[pkgIndex].id,
        packageName: this.items[pkgIndex].packageName,
        subscriptionPeriod: selectedPeriod,
        SubscriptionFees: subscriptionFees
      }
      this.$store.dispatch("updateRequestPackage", pkg);
      this.$router.push('/userRegisterationInfo');


      // console.log(this.packages[pkgIndex]);
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      if (lang == 'ar')
        this.$vuetify.rtl = true;
      else
        this.$vuetify.rtl = false;
    },
    getReprotsList(itemreportsArr, ix) {
      //console.log(this.reprotsList);

      const rl = this.reprotsList.filter(x => itemreportsArr != null && itemreportsArr.some(i => x.reportId == i))
        .slice(0, 5 + ix);

      return rl;
    },
    async sendMainForm() {
      // var x = document.getElementById("snackbar");
      // x.className = "show";
      // setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

      // await  axios.post(`${this.$baseURL}/api/Contact/Subscribe`, this.$data.formValues)
      //     .then(function (response) {
      //       console.log(response);

      var x = document.getElementById("snackbar");
      x.className = "show";
      setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);







    }
  }, computed: {

  },
  mounted() {

    var defaultThemeMode = "light"; var themeMode; if (document.documentElement) { if (document.documentElement.hasAttribute("data-bs-theme-mode")) { themeMode = document.documentElement.getAttribute("data-bs-theme-mode"); } else { if (localStorage.getItem("data-bs-theme") !== null) { themeMode = localStorage.getItem("data-bs-theme"); } else { themeMode = defaultThemeMode; } } if (themeMode === "system") { themeMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"; } document.documentElement.setAttribute("data-bs-theme", themeMode); }

    this.$axios({
      method: 'get',
      url: `${this.$baseURL}/SubscriptionPackage/PublishedSubscriptionPackage`,
    })
      .then((response) => {
        this.items = response.data;
        this.items.forEach(i => {
          if (i.selectedReports) {
            i.reports = i.selectedReports.split(",");
          }
        });
      });

    this.$axios({
      method: 'get',
      url: `${this.$baseURL}/SubscriptionPackage/reportsList`,
    })
      .then((response) => {
        this.reprotsList = response.data.filter(function (itm) {
          return itm.parentReportId == null;
        });
      });

    if (location.hash) {
      VueScrollTo.scrollTo(location.hash);
      }
  }
}
</script>
